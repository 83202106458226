// Generated by Framer (4c8d0fb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["aiADjovG9", "PqEfEqpbX", "ycWKW1XsZ", "x1azaP8lM"];

const serializationHash = "framer-oFyD1"

const variantClassNames = {aiADjovG9: "framer-v-w0qm7r", PqEfEqpbX: "framer-v-1dexfx1", x1azaP8lM: "framer-v-1j4lxt7", ycWKW1XsZ: "framer-v-107yhkb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Desktop: "aiADjovG9", Laptop: "PqEfEqpbX", Phone: "x1azaP8lM", Tablet: "ycWKW1XsZ"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "aiADjovG9"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aiADjovG9", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-w0qm7r", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"aiADjovG9"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-7ea6b336-6e98-44f5-8954-63f32d2e302f, rgb(233, 238, 251))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}} {...addPropertyOverrides({PqEfEqpbX: {"data-framer-name": "Laptop"}, x1azaP8lM: {"data-framer-name": "Phone"}, ycWKW1XsZ: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-oFyD1.framer-sc8agr, .framer-oFyD1 .framer-sc8agr { display: block; }", ".framer-oFyD1.framer-w0qm7r { height: 200px; overflow: hidden; position: relative; width: 1296px; will-change: var(--framer-will-change-override, transform); }", ".framer-oFyD1.framer-v-1dexfx1.framer-w0qm7r { width: 1080px; }", ".framer-oFyD1.framer-v-107yhkb.framer-w0qm7r { width: 729px; }", ".framer-oFyD1.framer-v-1j4lxt7.framer-w0qm7r { width: 351px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 1296
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"PqEfEqpbX":{"layout":["fixed","fixed"]},"ycWKW1XsZ":{"layout":["fixed","fixed"]},"x1azaP8lM":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerl5WllgzIq: React.ComponentType<Props> = withCSS(Component, css, "framer-oFyD1") as typeof Component;
export default Framerl5WllgzIq;

Framerl5WllgzIq.displayName = "AustellerTabelle";

Framerl5WllgzIq.defaultProps = {height: 200, width: 1296};

addPropertyControls(Framerl5WllgzIq, {variant: {options: ["aiADjovG9", "PqEfEqpbX", "ycWKW1XsZ", "x1azaP8lM"], optionTitles: ["Desktop", "Laptop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerl5WllgzIq, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})